exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-calisma-alanlarimiz-js": () => import("./../../../src/pages/calisma-alanlarimiz.js" /* webpackChunkName: "component---src-pages-calisma-alanlarimiz-js" */),
  "component---src-pages-ileti-gonderimi-riza-beyani-js": () => import("./../../../src/pages/ileti-gonderimi-riza-beyani.js" /* webpackChunkName: "component---src-pages-ileti-gonderimi-riza-beyani-js" */),
  "component---src-pages-iletisim-js": () => import("./../../../src/pages/iletisim.js" /* webpackChunkName: "component---src-pages-iletisim-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kisisel-verilerin-islenmesine-iliskin-aydinlatma-metni-aydinlatma-metni-js": () => import("./../../../src/pages/kisisel-verilerin-islenmesine-iliskin-aydinlatma-metni-aydinlatma-metni.js" /* webpackChunkName: "component---src-pages-kisisel-verilerin-islenmesine-iliskin-aydinlatma-metni-aydinlatma-metni-js" */),
  "component---src-pages-makale-js": () => import("./../../../src/pages/makale.js" /* webpackChunkName: "component---src-pages-makale-js" */)
}

