/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import ReactDOM from "react-dom"
import "./src/assets/index.scss"
import "./src/assets/css/magnific-popup.css"
import "./src/assets/style.scss"
import "./src/assets/business/business.css"
import "./src/assets/business/css/fonts.css"
import "./src/assets/business/css/font-icons.css"
const loadableReady = require("@loadable/component").loadableReady

const removeScript = url => {
  let scripts = document.getElementsByTagName("script")
  for (var i = scripts.length; i--; ) {
    var baseUrl = window.location.protocol + "//" + window.location.host
    let scriptUrl = scripts[i].src.replace(baseUrl, "")
    if (scriptUrl === url) scripts[i].parentNode.removeChild(scripts[i])
  }
}

const addScript = url => {
  const script = document.createElement("script")
  let scripts = document.getElementsByTagName("script")
  let hasElement = false
  script.src = url
  script.async = false
  for (var i = scripts.length; i--; ) {
    var baseUrl = window.location.protocol + "//" + window.location.host
    let scriptUrl = scripts[i].src.replace(baseUrl, "")
    if (scriptUrl === url) hasElement = true
  }
  if (!hasElement) {
    document.body.appendChild(script)
  }
}

export const onPreRouteUpdate = () => {
  removeScript(
    "https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/js/bootstrap.min.js",
  )
}

/* eslint-disable */
export const onRouteUpdate = () => {
  addScript(
    "https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/js/bootstrap.min.js",
  )
  function add_class_on_scroll(className) {
    let header = document.getElementById("header")
    if (header) {
      header.classList.add(className)
    }
  }

  function remove_class_on_scroll(className) {
    let header = document.getElementById("header")
    if (header) {
      header.classList.remove(className)
    }
  }

  function adjustScroll() {
    let scrollpos = window.scrollY
    // let width = window.innerWidth;

    if (scrollpos > 10) {
      add_class_on_scroll("sticky-header")
    } else {
      remove_class_on_scroll("sticky-header")
    }

    if (scrollpos > 80) {
      add_class_on_scroll("sticky-header-shrink")
    } else {
      remove_class_on_scroll("sticky-header-shrink")
    }
  }

  function adjustWidth() {
    let width = window.innerWidth
    let jumbo = document.getElementById("jumbo")
    if (width > 991) {
      if (jumbo) {
        jumbo.style.cssText = "margin-top: -82px"
      }
    } else {
      if (jumbo) {
        jumbo.style.cssText = "margin-top: 0px"
      }
      $(".sub-menu-container").hide()
    }
  }

  function init() {
    const targetNode = document.body
    // Options for the observer (which mutations to observe)
    const config = { attributes: true, childList: true, subtree: true }

    const callback = (mutationsList, observer) => {
      if (
        $("#primary-menu-trigger").length &&
        $(".sub-menu-trigger").length &&
        $("#menu").length &&
        document.querySelectorAll("[data-scrollto]").length
      ) {
        adjustScroll()
        adjustWidth()
        $("body").removeClass("primary-menu-open")
        $("#primary-menu-trigger").on("click", () => {
          $("body").toggleClass("primary-menu-open")
          $("#menu").slideToggle()
        })
        $(".sub-menu-trigger").on("click", e => {
          $(e.target).siblings(".sub-menu-container").slideToggle()
          $(e.target).toggleClass("icon-rotate-90")
        })
        let scrollElements = document.querySelectorAll("[data-scrollto]")
        scrollElements.forEach(el => {
          el.addEventListener("click", e => {
            let goTo = document.getElementById(
              el.getAttribute("data-scrollto").replace("#", ""),
            )
            if (goTo) {
              e.preventDefault()
              window.scrollTo({
                top: goTo.offsetTop,
                left: 0,
                behavior: "smooth",
              })
            }
          })
        })
        observer.disconnect()
      }
    }
    const observer = new MutationObserver(callback)

    // Start observing the target node for configured mutations
    observer.observe(targetNode, config)
  }

  window.addEventListener("scroll", function () {
    adjustScroll()
  })

  window.addEventListener("resize", function () {
    adjustWidth()
  })

  $(document).on("ready", init())
}
/* eslint-enable */

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    loadableReady(() => {
      ReactDOM.render(element, container, callback)
    })
  }
}

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  const { pathname, hash } = location

  if (
    pathname === "/calisma-alanlarimiz" ||
    !pathname.includes("/calisma-alanlarimiz/")
  ) {
    window.scrollTo(0, 0)
  }

  if (hash) {
    let goTo = document.getElementById(hash.replace("#", ""))
    window.scrollTo({
      top: goTo.offsetTop,
      left: 0,
      behavior: "smooth",
    })
  }

  return false
}
